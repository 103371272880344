if (typeof window.acpv !== 'object') {
    window.acpv = {};
}

function updateTradeinValue(value) {
    if (value != 0) {
        const tval = value.toLocaleString();
        $('input[name="tradein"]').val(tval).trigger("change");
        $('.pr-estimate span.ng-binding').text(`$${tval}`);
    }
}

window.acpv.createJumpLink = function (destination) {
	if (window.jumpUrl) {
		return window.jumpUrl.replace('{0}', encodeURIComponent(destination));
	}

	return '/go?to=' + encodeURIComponent(destination);
};

$(document).ready(function() {
	$('a[href^="/shopping/"]').each(function()
	{ 
		this.href = window.acpv.createJumpLink(this.getAttribute('href'));
	});
});
